import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo"
import './privacypolicy.css'

export default ({data: {datoCmsVariousfragment: p}}) => {
  return <Layout>
    <SEO title='Privacy Policy' seo={p.seoMetaTags}/>
    <div id='privacypolicy' dangerouslySetInnerHTML={{__html: p.privacypolicy}} className={'font-sans font-light my-4 mx-2 lg:mx-32'}/>
  </Layout>;
}

export const query = graphql`
    query PrivacyPolicy {
        datoCmsVariousfragment {
            privacypolicy
        }
    }
`